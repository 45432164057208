<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper marginB20" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <el-form-item :label="$t('searchModule.Audit_status')">
            <el-select
              v-model.trim="formInline.authStatus"
              @change="auth"
              style="width: 150px"
              placeholder=""
            >
              <el-option label="请选择认证状态" value=""></el-option>
              <el-option label="待审核" value="0"></el-option>
              <el-option label="已审核" value="1"></el-option>
              <el-option label="已作废" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          border-collapse:
          collapse
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          @sort-change="sortChange"
        >
          <!-- <el-table-column type="index" :label="$t('list.index')" width="70" align='center'></el-table-column> -->
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="提交人" width="" align="center">
            <template slot-scope="scope">
              <!-- <span :class='{red: scope.row.equipmentState == 0}'>{{scope.row.equipmentState == 1? '在线' : '离线'}}</span> -->
              <span>{{ scope.row.mobile }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.Audit_status')" width="" align="center">
            <template slot-scope="scope">
              <p v-if="scope.row.authStatus == 1">
                <span
                  style="
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: red;
                    display: inline-block;
                  "
                ></span>
                <span>已审核</span>
              </p>
              <p v-if="scope.row.authStatus == 0">
                <span
                  style="
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: #108de9;
                    display: inline-block;
                  "
                ></span>
                <span>待审核</span>
              </p>
              <p v-if="scope.row.authStatus == 2">
                <span
                  style="
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: #989898;
                    display: inline-block;
                  "
                ></span>
                <span>已作废</span>
              </p>
            </template>
          </el-table-column>
          <el-table-column
            :prop="item.prop"
            border
            :label="item.label"
            :width="item.width"
            :sortable="item.sortable"
            v-for="item in tableCols"
            :key="item.label"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="100"
            v-if="$route.meta.authority.button.detail || $route.meta.authority.button.auth"
          >
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.authStatus == 0 && $route.meta.authority.subPage.detail.button.auth"
                type="text"
                style="width: 70px; margin: 0"
                @click="audit(scope.row)"
                >审核</el-button
              >
              <el-button
                v-if="
                  (scope.row.authStatus == 2 && $route.meta.authority.button.detail) ||
                  (scope.row.authStatus == 1 && $route.meta.authority.button.detail)
                "
                type="text"
                style="width: 70px; margin: 0"
                @click="detail(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total > 0"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request, exportExcelNew } from "../../common/js/public.js";
export default {
  name: "memberIdcardAuth",
  components: {},
  data() {
    return {
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      parkList: [],
      tableCols: [
        {
          prop: "operation",
          label: this.$t("list.Operator"),
          width: "120",
        },
        {
          prop: "applyTime",
          label: "提交日期",
          width: "200",
        },
        {
          prop: "authTime",
          label: "更新日期",
          width: "",
          // formatter: function(row, column, cellValue, index) {
          //   switch (cellValue) {
          //     case 1:
          //       return '在职'
          //     case 2:
          //       return '离职'
          //     default: '-'
          //       break;
          //   }
          // }
        },
      ],
      tableData: [],
      formInline: {
        authStatus: "",
      },
    };
  },
  methods: {
    auth() {
      this.page = 1;
      this.searchData();
    },
    sortChange(column, prop, order) {
      if (column.prop === "createTime") {
        this.formInline.orderBy = 0;
        this.formInline.direction = column.order === "ascending" ? "0" : "1";
        this.searchData();
      }
    },
    clearFrom() {
      this.$refs.autocompleteParkName.clearName();
      this.$refs.operationName.clearName();
      this.formInline = {
        operationId: "",
        areaId: "",
      };
    },
    exportExcel() {
      // if (this.total > 1000) {
      //   this.$alert('导出记录条数不能大于1000条', '提示')
      //   return false
      // }
      exportExcelNew("/acb/2.0/projectPlan/exportMainPlan", {
        pageNum: this.page,
        pageSize: this.pageSize,
        ...this.formInline,
      });
    },
    audit(cmd, data) {
      this.$router.push({ path: "/identityauthenticationDetail", query: cmd });
    },
    detail(cmd, data) {
      this.$router.push({ path: "/identityauthenticationDetail", query: cmd });
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    completeValue(item) {
      this.formInline.parkId = item;
    },
    // 搜索
    searchData() {
      this.loading = true;
      let opt = {
        method: "post",
        url: "/acb/2.0/memberIdcardAuth/query",
        data: {
          authStatus: this.formInline.authStatus,
          page: this.page,
          pageSize: this.pageSize,
        },
        success: (res) => {
          if (res.state == 0) {
            this.loading = false;
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      request(opt);
    },
  },
  beforeDestroy() {},
  activated() {
    this.searchData();
  },
  created() {
    // this.searchData();
  },
  computed: {},
  mounted() {
    if (this.$route.meta.authority) {
      let data = {};
      if (sessionStorage.getItem("authority")) {
        data = JSON.parse(sessionStorage.getItem("authority"));
      }
      data["parkList"] = this.$route.meta.authority;
      sessionStorage.setItem("authority", JSON.stringify(data));
    }
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  overflow hidden
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
.el-dropdown-link
  cursor pointer
</style>
